@import "./settings/variable.scss"; //themes' colors

//base...
@import "generic/normalize.scss";
@import "generic/box-sizing.scss";
@import "./base/scaffolding"; // styles of base elements
@import "./base/typography"; // base styles of h1-h6, p, span

//components...
@import "component/btn";
@import "./component/card";
@import "./component/check-box";
@import "./component/sidebar";
@import "./component/topbar";
@import "./component/load";
@import "./component/form";
@import "./component/wizard";
@import "./component/home";
@import "./component/homepage";
@import "./component/progress-bar";
@import "./component/progressbar";
@import "./component/tabs";
@import "./component/table";
@import "./component/dashboard";
@import "./component/charts";
@import "./component/panel";
@import "./component/modal";
@import "./component/react-select";
@import "./component/material-form";
@import "./component/timeline";
@import "./component/topbar";
@import "./component/pagination.scss";
@import "./component/dropdown.scss";
@import "./component/toggle-btn.scss";
@import "./component/slider.scss";

//pages...
@import "containers/account";

//objects...
@import "objects/layout.scss";

.selected {
 border-left: 5px solid #4ce1b6;
}
