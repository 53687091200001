.client-delete-column {
  display: none;
}

.table-client-row:hover > .client-column > .table-client-column > .client-delete-column {
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.dimensions > div > ul li::marker {
  color: #70bbfd !important;
}

.show-price {
  cursor: pointer;
  width: fit-content;
}

.background-danger {
  background: #ff000045;
}

.cursor-pointer {
  cursor: pointer;
}

.weekly-chart .col {
  padding: 0;
}
.toast-success {
  background-color: #4ce1b6 !important;
}

.scenario-btn {
  padding: 10px 10px !important;
  line-height: 0 !important;
  background-color: #70bbfd !important;
  border-color: #70bbfd !important;
  color: #ffffff !important;
}

.scenario-btn > svg {
  fill: #ffffff !important;
}

@media (min-width: 576px) {
  .link-share-modal {
    max-width: 80vw !important;
  }
}

.review-suggestion-h-icon {
  height: 18px !important;
  width: 18px !important;
}

.review-suggestion-label {
  font-size: 20px;
  cursor: pointer;
}
.review-suggestion-description {
  font-size: 20px;
}

.total-annual-cost {
  color: #ff6384 !important;
}

.total-service {
  color: #36a2eb !important;
}
.validation-rows {
  cursor: pointer;
}
.background-success {
  background: #c3e6cb;
}
.background-warning {
  background: #ffeeba;
  color: #856404;
}

.slide-pane_from_right {
  margin-top: 64px;
}

.slide-pane__content {
  padding: 0px 0px 35px 0px !important;
}

.slide-pane__header {
  flex-direction: row-reverse;
  background: #ffffff !important;
}

.client-server-title {
  font-size: 20px;
  color: #70bbfd !important;
}

// .step-four-detail-card .card{
//   min-height: 280px !important;
//   max-height: 100%;
// }

.containers {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.back-btn {
  cursor: pointer;
}

.assumption-number {
  font-size: 21px;
  color: #70bbfd !important;
}

.avatar {
  width: 30px;
  height: 30px;
  // background: red;
  // border: 1px solid red;
  border-radius: 50px;
}
.avatar1 {
  width: 30px;
  height: 30px;
  // background: blue;
  border-radius: 50px;
}

.user-img {
  border-radius: 100px;
}

.wizard-form-one-title {
  font-size: 20px;
}

.wizard-tooltip {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.form-four-title {
  // color: #70bbfd !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

/*form-4*/

.form-four-tabs-title {
  font-size: 25px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 15px;
}

.form-four-subTitle {
  font-size: 30px;
  font-weight: 500;
  color: #70bbfd !important;
}

.server-type-title {
  font-size: 20px;
  color: #70bbfd !important;
}

.bottom-title {
  font-size: 20px;
}

.step-four .card {
  border: 2px solid #70bbfd;
}

.server-instance-name {
  font-size: 15px;
  color: #646777;
  text-align: center;
  line-height: 30px;
}
.server-instance-label {
  font-size: 15px;
  color: #646777;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
}

.server-not-available {
  font-size: 30px;
  // color: #ff6384;
}
.chart-container {
  display: flex;
  height: 330px;
  /* width: 85%; */
  width: 330px;
  /* border: 1px solid; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pricing-icon {
  &.expand {
    transform: rotate(90deg);
  }

  transition: transform 300ms;
}

.user-profile-container-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #444444 !important;
  mix-blend-mode: normal;
  opacity: 0.6;
}
.pointer {
  cursor: pointer !important;
}
.summery-tale {
  // table tr td {
  //   border-right: 1px solid blue;
  //   color: black;
  // }

  table tr td:nth-child(5) {
    border-right: 2px solid #eff1f5;
  }
  table thead tr th:nth-child(5) {
    border-right: 2px solid #eff1f5;
  }
  table thead tr th {
    white-space: nowrap;
  }
  table tr td:last-child {
    white-space: nowrap;
  }
}
/*ServerInstanceName*/

.storage-title {
  font-size: 20px;
  margin-bottom: 25px;
  color: #70bbfd !important;
}
.storage-sub-title-one {
  font-size: 20px;
  margin-bottom: 30px;
}
.storage-sub-title {
  font-size: 20px;
}

/*Manual Search*/

.query-builder-container {
  padding: 0px !important;
}

.query-builder {
  margin: 0px !important;
}

.manual-search-title {
  color: #70bbfd !important;
}
.group {
  background: #70bbfd24 !important;
  border: 1px solid #70bbfd !important;
}

.ant-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.ant-btn:hover {
  background-color: #70bbfd !important;
  border-color: #70bbfd !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.storage-label {
  font-size: 17px;
}

// .form select {
//   width: 100%;
//   padding: 5px 10px;
//   font-size: 12px;
//   height: 32px;
//   transition: border 0.3s;
//   background: transparent;
//   border: 1px solid #f2f4f7;
//   color: #646777;
// }

.info-tip {
  width: 25px;
  height: 25px;
  color: #70bbfd;
}

// .loading {
//   position: absolute;
//   left: 50%;
// }

.delete-icon {
  color: #ff4861;
  // padding: 5px;
}

// .confirm-dialog-title {
//   font-size: 20px;
//   font-weight: bold;
//   color: #4f5155;
// }
// .confirm-dialog-sub-title {
//   font-size: 15px;
//   color: #7b7c89;
// }

@media (max-width: 767px) {
  .recommended-record {
    border: 1px solid #e7e2e2;
  }
}

// .logout-btn p {
//   margin: 0;
//   font-size: 14px;
//   line-height: 16px;
//   color: #646777;
//   display: flex;
//   padding: 9px 20px;
//   transition: all 0.3s;
//   height: 32px;
//   width: 100%;
//   position: relative;
//   cursor: pointer;
//   background: none;
//   border: none;
// }

// .logout-icon svg {
//   fill: #dddddd;
//   width: 15px;
//   padding-bottom: 8px;
// }

// .noresult-found-title {
//   font-size: 25px;
//   color: #70bbfd !important;
// }

// .step-one-title {
//   font-size: 20px;
//   font-weight: 400;
// }
// .step-one-title-1 {
//   font-size: 20px;
//   font-weight: 500;
// }

.scenario-sub-title {
  font-size: 15px !important;
  display: flex !important;
  cursor: pointer;
  line-height: 1;
}

// .add-icon {
//   color: #70bbfd;
// }

// .welcome-title {
//   font-size: 20px;
// }

.welcome-title-card {
  font-size: 15px !important;
  display: flex !important;
  color: #4ce1b6 !important;
  font-weight: bold;
}
.welcome-server-card {
  font-size: 15px !important;
  display: flex !important;
  font-weight: bold;
}

.server-length-title {
  font-size: 20px;
  color: #70bbfd !important;
  font-size: 20px;
  font-weight: bold;
}

// server-detail card
.server-detail {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: 0.25rem;
    padding-bottom: 0;
  }
  .form__form-group {
    margin-bottom: 0px;
    width: 100%;
    position: relative;
  }

  .panel__body {
    padding: 0;
  }
  .panel__title {
    margin: 0;
  }
  .server-detail-title {
    word-break: break-all;
  }
  @media (max-width: 425px) {
    .server-detail-card {
      padding: 0;
    }
  }
}

.percentInput {
  position: relative;
}
.percentInput span {
  position: absolute;
  right: 20px;
  top: 5px;
}
.num_percent {
  text-align: right;
  padding-right: 12px;
}

@media (max-width: 575.98px) {
  .annual-cost {
    text-align: center;
  }
  .small-btn {
    display: block;
    width: 100%;
    margin-right: 0;
  }
  .slide-pane {
    width: 100% !important;
  }
  .form-four-title {
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }
  .welcome-title {
    font-size: 15px;
  }
  .welcome-title-card {
    font-size: 15px !important;
  }
  .wizard__step p {
    font-size: 10px;
  }
  .containers {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 376px) {
  .wizard__step p {
    font-size: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .account__btn--small {
    line-height: 1 !important;
  }
  .slide-pane {
    width: 85% !important;
  }
  .containers {
    grid-template-columns: repeat(1, 1fr);
  }
  .wizard__step p {
    font-size: 12px;
  }
  .form-four-title {
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }
  .welcome-title {
    font-size: 15px;
  }
  .welcome-title-card {
    font-size: 15px !important;
  }
}

@media (max-width: 991.98px) {
  .sm-hr {
    display: block;
  }

  .review-suggestion-card .card-body {
    padding: 0;
  }
  .step-four-card-size {
    height: fit-content;
  }

  .pricing-modal .card-body {
    padding: 0px;
  }

  .total-cost {
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .containers {
    grid-template-columns: repeat(1, 1fr);
  }
  .form-four-title {
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }
  .welcome-title-card {
    font-size: 15px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .sm-hr {
    display: none;
  }
  .client-server-card {
    min-height: 85%;
  }
  .welcome-title-card {
    font-size: 15px !important;
  }
  .welcome-title-card {
    font-size: 15px !important;
  }
}

@media (min-width: 1200px) {
  .sm-hr {
    display: none;
  }
  .client-server-card {
    min-height: 85%;
  }
}

//Users

.user-tab-date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #787985 !important;
}
.user-badge {
  background: #f66c7d;
  padding: 5px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
  text-transform: uppercase;
  color: #ffffff;
}

.user-profile-icon {
  width: 100%;
  // min-width: 7vw;
  // min-height: 7vw;
  min-width: 90px;
  min-height: 90px;
  max-width: 100px;
  max-height: 100px;
  height: auto;
  // width: 100%;
  // max-width: 80px;
  // height: auto;
  border-radius: 100%;
  margin-right: 10px;
  object-fit: cover;
}

.user-profile-name {
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
}
.user-profile-categories {
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  mix-blend-mode: normal;
  opacity: 0.6;
}

.user-profile-email {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #444444;
  word-break: break-word;
}

.user-profile-phone {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #444444;
}

.date-prev {
  font-size: 8px !important;
}
.user-profile-clients {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #70bbfd !important;
}

.user-profile-clients-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #999999 !important;
}

.user-profile-count {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}
.user-profile-icons {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  overflow: hidden;
}
.user-personal-badge {
  background: #f66c7d;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9px !important;
  line-height: 11px;
  text-transform: uppercase;
}
.user-personal-badge-green {
  background: #4ce1b6;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9px !important;
  line-height: 11px;
  text-transform: uppercase;
}
.user-personal-badge-yellow {
  background: #f6da6e;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9px !important;
  line-height: 11px;
  text-transform: uppercase;
}
.user-name-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #56cba9 !important;
}

.user-name-description {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #727381 !important;
}

//Assugnments
.assugnments-project-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #555555;
}

.overlapping-img {
  vertical-align: top !important;
}

.overlapping-img > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.overlapping-img > ul > li > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
  border: 2px solid white;
}
.overlapping-img > ul > li {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

// .overlapping-img > ul > li:nth-child(1) {
//   background: blue;
// }
// .overlapping-img > ul > li:nth-child(2) {
//   background: green;
// }
// .overlapping-img > ul > li:nth-child(3) {
//   background: purple;
// }
// .overlapping-img > ul > li:nth-child(4) {
//   background: tomato;
// }
.overlapping-img > ul > li:nth-child(n + 2) {
  margin-left: -30px;
}

.overlapping-img > ul:hover li:nth-child(n + 2) {
  margin-left: -10px;
}

@media (max-width: 575.98px) {
  .user-profile-card {
    flex-direction: column;
  }
  .user-profile-details {
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .user-profile-icon {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .user-profile-card {
    flex-direction: row;
  }
  .user-profile-count {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
}

//new clients

.assign-project-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #555555 !important;
}

.assign-project-sub-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #999999 !important;
}

.client-table-search {
  flex-direction: row;
  display: flex;
}

.client-dropdown-icon > p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #999999 !important;
}
.client-dropdown-icon {
  width: 225px;
  border-radius: 0;
  // max-width: 225px;
}
.client-project-manager-search-input > input:focus {
  box-shadow: none !important;
}
.client-project-manager-search-input > input::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #999999 !important;
}
.client-project-manager-search-input > input {
  border-radius: 0;
  border-right: 0;
  border: 1px solid #eaeaea !important;
}

.clients-search-icon > svg {
  fill: #ffffff !important;
}
.clients-search-icon {
  background: #70bbfd !important;
  border: 1px solid #70bbfd !important;
  border-left: 0 !important;
  padding: 6px 9px;
}

.client-profile-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #555555;
}

.assign-project-table table > tbody > tr > td:nth-child(3) {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #70bbfd;
}

@media (max-width: 991px) {
  .assign-project-title {
    font-size: 18px;
  }
  .assign-project-sub-title {
    font-size: 12px;
  }
}
@media (max-width: 575.98px) {
  .client-table-search {
    flex-direction: column;
    display: flex;
  }
}

//Validation

.validation-dropdown-style {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 16px;
  color: #000000 !important;
  height: calc(1.5em + 0.75rem + 7px);
}
.validation-input-label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000 !important;
}
.validation-labels {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #444444 !important;
}
.validation-row {
  row-gap: 15px;
}

.validation-icon {
  width: 40px;
  height: 45px;
  height: calc(1.5em + 0.75rem + 7px);
}

.clients-validation-icon > svg {
  fill: #999999 !important;
  stroke-width: 3px !important;
  stroke: #999999 !important;
  stroke-linejoin: bevel !important;
  width: 13px !important;
}
.clients-validation-icon {
  background: none !important;
  border: 1px solid #eaeaea !important;
  border-left: 0 !important;
  padding: 6px 9px;
}

.add-circle-icon {
  width: auto;
  height: 41px;
  fill: #7cdeb8;
  stroke: #7cdeb8;
  stroke-width: 1px !important;
  transition: 0.8s all;
}
.add-circle-icon:hover {
  fill: #43d099;
}
.validation-badge-success {
  background-color: #4ce1b6;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
  line-height: 13px;
  padding: 7px 10px 7px 20px;
  border-radius: 100px;
}

.validation-badge-success > svg,
.validation-badge-primary > svg,
.validation-badge-danger > svg,
.validation-badge-warning > svg {
  height: 18px;
}

.validation-badge-primary {
  background-color: #70bbfd;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
  line-height: 13px;
  padding: 7px 10px 7px 20px;
  border-radius: 100px;
}
.validation-badge-danger {
  background-color: #ff4861;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
  line-height: 13px;
  padding: 7px 10px 7px 20px;
  border-radius: 100px;
}
.validation-badge-warning {
  background-color: #f6da6e;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
  line-height: 13px;
  padding: 7px 10px 7px 20px;
  border-radius: 100px;
}
.validation-remove-btn {
  position: absolute;
  right: 0px;
  z-index: 99;
}
.validation-update-btn {
  position: absolute;
  right: 60px;
  z-index: 99;
}

@media (max-width: 575.98px) {
  .validation-remove-btn {
    position: relative;
    right: 0px;
  }
}
@media (max-width: 991px) {
  .validation-badge-success {
    font-size: 12px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
  .validation-badge-primary {
    font-size: 12px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
  .validation-badge-danger {
    font-size: 12px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
  .validation-badge-warning {
    font-size: 12px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
}

//Assumption

.clients-assumption-icon {
  background: none !important;
  border: 1px solid #eaeaea !important;
  border-left: 0 !important;
  padding: 6px 9px;
}

.clients-assumption-icon > svg {
  stroke: none;
}
.assumption-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #444444 !important;
}

.assumption-labels {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000 !important;
}

.assumption-apply {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 13px;
  line-height: 15px;
  color: #444444 !important;
}

.assumption-os-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #999999 !important;
}

.dropdown-icon-label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999 !important;
}

.assumption-icon {
  width: 160px !important;
  height: calc(1.5em + 0.75rem + 7px);
}

.assumption-card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.assumption-dropdown-style {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 16px;
  color: #000000 !important;
  height: 30px !important;
  width: 60px !important;
  padding: 5px 10px !important;
}

.assumptions-icon {
  height: 30px !important;
  padding: 0px 2px !important;
}

.assumption-slider-close-icon > svg {
  width: 16px;
  fill: #999999;
  cursor: pointer;
}

.assumption-storage-add-btn {
  background: #70bbfd !important;
  color: #ffffff !important;
  border-color: #70bbfd !important;
}

//WorkSpace
.workspace-n-btn {
  position: absolute;
  right: 0;
  top: 8px;
}
